<template>
  <Modal v-model="modal" sticky scrollable :mask-closable="true" width="60%" class="custom-modal"
    @on-cancel="closeModal">
    <template #header>
      <p class="title">从数据源导入</p>
      <div class="modal-close" @click="closeModal">
        关闭 <span>ESC</span>
      </div>
    </template>
    <div class="form" v-if="current == 0&& modal">
      <Form ref="refForm" :model="form" :rules="rules" label-position="top">
        <FormItem label="连接方式" prop="type">
          <div class="type">
            <div class="radio">
              <RadioGroup v-model="form.type">
                <Radio label="metrics">互操作接口</Radio>
                <Radio label="DataSpace">DataSpace</Radio>
              </RadioGroup>
            </div>

            <p class="intro">
              <Icon type="ios-bulb" />{{ intro }}
            </p>
          </div>
        </FormItem>
        <FormItem :label="`数据地址`" prop="interfaceUrl">
          <Input v-model.trim="form.interfaceUrl" placeholder="请输入" />
          <Tooltip content="数据地址获取：可使用DataSpace的在线服务地址，如:https://dataspace.casdc.cn" placement="right" theme="light"
            max-width="400" v-if="form.type == 'DataSpace'">
            <Icon type="ios-help-circle-outline" />
          </Tooltip>
          <Tooltip content="数据地址获取：可使用互操作接口认定时填写的互操作API地址（InstDB用户的数据地址为：https://ip:端口/api/fair/entry）"
            placement="right" theme="light" max-width="400" v-if="form.type == 'metrics'">
            <Icon type="ios-help-circle-outline" />
          </Tooltip>
        </FormItem>
        <FormItem label="授权码" prop="secretKey">
          <Input v-model.trim="form.secretKey" placeholder="请输入" />
          <Tooltip content="授权码取：管理员登录系统后，通过“开放API”-“应用管理”菜单进入页面后，右上角添加生成后获取。" placement="right" theme="light"
            max-width="400" v-if="form.type == 'DataSpace'">
            <Icon type="ios-help-circle-outline" />
          </Tooltip>
          <Tooltip content="授权码获取：管理员可登录InstDB工作台，通过“管理配置”-“发布授权”菜单右上角添加授权码生成后获取。" placement="right" theme="light"
            max-width="400" v-if="form.type == 'metrics'">
            <Icon type="ios-help-circle-outline" />
          </Tooltip>
        </FormItem>
        <FormItem label="APP ID" prop="appKey" v-if="form.type == 'DataSpace'">
          <Input v-model.trim="form.appKey" placeholder="请输入" />
          <Tooltip placement="right" theme="light" max-width="400">
            <Icon type="ios-help-circle-outline" />
            <template #content>
              <p>
                AppKey获取：管理员登录系统后，通过“开放API”-“应用管理”菜单进入页面后，右上角添加生成后获取。
              </p>
            </template>
          </Tooltip>
        </FormItem>
        <FormItem style="text-align: center">
          <div class="btn btn-simple" @click="next">下一步</div>
        </FormItem>
      </Form>
    </div>
    <div class="list" v-if="current == 1&& modal">
      <p class="orange">请选择数据目录</p>
      <div class="list-box">
        <div class="list-box-header">
          <div>
            <Checkbox v-model="all" @on-change="changeAll">全选</Checkbox>
            <Page :total="total" prev-text="上一页" next-text="下一页" @on-change="handlePage" />
          </div>
          <div>
            <Input search placeholder="请输入" v-model="keyword" @on-search="handleSearch" />
          </div>
        </div>
        <div class="list-box-content">
          <div class="list-item" v-for="(item, i) in tableData" :key="i">
            <div class="checkbox">
              <Checkbox v-model="item.checked" @on-change="changeItem(item, i)"></Checkbox>
            </div>
            <div class="list-item-info">
              <h2>{{ item.name }}</h2>
              <p>{{ item.desc }}</p>
              <div class="flex-center-between count">
                <span></span>
                <span>{{ item.fileNumber }}个数据文件</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-footer">
        <div class="count">
          <p class="margin-right-20">
            共 <span class="bold">{{ total }}</span>项
          </p>
          <p>
            已选<span class="bold">{{ selection.length }}</span>项
          </p>
        </div>
        <div>
          <div class="btn btn-simple margin-right-10" @click="prev">上一步</div>
          <div class="btn btn-default" @click="submit">确认</div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="btn btn-simple margin-right-10" @click="prev">取消</div>
      <div class="btn btn-default" @click="submit">确认</div>
    </template>
  </Modal>
</template>
  
  <script setup>
import { Modal, Message } from "view-ui-plus";
import { ref, reactive, inject, watch } from "vue";
import { useRouter } from "vue-router";
import qs from "qs";
import { ElLoading } from "element-plus";

let refForm = ref(null);
let modal = ref(false);
let router = useRouter();
let form = ref({
  type: "metrics",
  interfaceUrl: "",
  appKey: "",
  secretKey: "",
});

let axios = inject("axios");
let rules = ref({
  type: [{ required: true, message: "请选择连接方式", trigger: "change" }],
  interfaceUrl: [
    { required: true, message: "请输入数据地址", trigger: "blur" },
  ],
  secretKey: [{ required: true, message: "请输入授权码", trigger: "blur" }],
  appKey: [{ required: true, message: "请输入APP Id", trigger: "blur" }],
});

const modify = () => {
  modal.value = true;
};
//将方法暴露给父组件使用
defineExpose({ modify });


const Loading = ref(null);
const openFullScreen = () => {
  Loading.value = ElLoading.service({
    lock: true,
    background: "rgba(255, 255,255, 0.5)",
  });
};


//下一步
let current = ref(0);
const next = () => {
  refForm.value.validate((valid) => {
    if (valid) {
      current.value++;
      handleSearch();
    } else {
      Message.error({
        background: true,
        content: "请检查必填项是否填写完整",
      });
    }
  });
};

let tableData = ref([]);

//关闭
const closeModal = () => {
  keyword.value = ''
  current.value = 0
  form.value = {
    type: "metrics",
    interfaceUrl: "",
    appKey: "",
    secretKey: "",
  };
  modal.value = false
};


//上一步
const prev = () => {
  current.value--;
};
let all = ref("");
let keyword = ref("");
let page = ref(1);
let pageSize = ref(10);
let total = ref(0);
let selection = ref([]);

const handlePage = (val) => {
  page.value = val;
  getTableData();
};
watch(
  () => tableData.value,
  (val) => {
    selection.value = val.filter((item) => {
      return item.checked;
    });
  },
  {
    deep: true,
  }
);
const getTableData = () => {
  openFullScreen()



  let url = `/general/call`;
  let params;
  let { interfaceUrl, appKey, secretKey, type } = form.value;
  if (type == "DataSpace") {
    params = {
      appKey,
      interfaceUrl,
      secretKey,
      type,
      page: page.value - 1,
      size: pageSize.value,
      name: keyword.value
    };
  } else {
    params = {
      appKey,
      interfaceUrl,
      secretKey,
      type,
      // name: "",
      dataCenterId: "",
      page: page.value - 1,
      size: pageSize.value,
      name: keyword.value
    };
  }
  console.log(params);
  axios.get(url, { params }).then((res) => {
    // console.log(res);
    Loading.value.close();

    if (res.data.code == 200) {
      let data = res.data.data;
      tableData.value = data.data.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
      total.value = data.total;
    } else {
      tableData.value = [];
    }
  }).catch(err => {
    Loading.value.close();

  });
};
const handleSearch = () => {
  page.value = 1;
  getTableData();
};

const changeAll = (val) => {
  if (val) {
    tableData.value.forEach((item) => {
      item.checked = true;
    });
  } else {
    tableData.value.forEach((item) => {
      item.checked = false;
    });
  }
};
const changeItem = (row, i) => {
  let isChecked = true;
  tableData.value.forEach((item) => {
    if (!item.checked) {
      isChecked = false;
    }
  });
  all.value = isChecked;
};

//确认提交
const submit = () => {
  selection.value.forEach((item) => {
    delete item.checked;
  });
  let url = `/general/importDb`;
  let arr = selection.value;

  let params = {

    metricParam: {
      metrics: arr,
      type: "重要数据",
    },
  };
  axios
    .post(url, params, {
      headers: {
        "content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res);
      if (res.data.code == 200) {
        Message.success({
          background: true,
          content: "导入成功",
        });
        modal.value = false;
        router.push({ name: "FillingList" });
      } else {
        Message.error({
          background: true,
          content: res.data.message,
        });
      }
    });
};

//切换描述
let intro = ref(
  "互操作接口是院科学数据中心体系建立的一套面向科学数据中心互联互通的互操作协议，在保证全流程可追溯的前提下，实现数据中心系统间的数据读取、流转、机器可操作。您需要先获得科学数据中心的数据地址及授权才可使用此功能。"
);
watch(
  () => form.value.type,
  (val) => {
    if (val == "DataSpace") {
      intro.value = `DataSpace是院科学数据软件栈推出的一款数据协同管理工具，支撑科研人员和科研项目团队进行日常科研数据的在线管理。您需要先获得DataSpace地址、AppID及授权才可使用此功能。`;
    } else {
      intro.value = `互操作接口是院科学数据中心体系建立的一套面向科学数据中心互联互通的互操作协议，在保证全流程可追溯的前提下，实现数据中心系统间的数据读取、流转、机器可操作。您需要先获得科学数据中心的数据地址及授权才可使用此功能。`;
    }
  }, { deep: true }
);
</script>
  
  <style lang="scss" scoped>
//弹框样式
.title {
  color: #3f3f3f;
  font-size: 22px;
  // margin-bottom: 20px;
}

.btn {
  font-size: 14px;
  //   margin: 0 auto;
}
.btn-default {
  padding: 0 25px;
}
.orange {
  color: #ffad59;
  line-height: 30px;
}
.type {
  border: 1px solid #ddd;
  border-radius: 5px;
  .radio {
    padding: 10px 10px;
  }
  p {
    padding: 0 10px;
    margin-bottom: 20px;
    font-size: 12px;
    i {
      font-size: 22px;
      color: #c12a21;
    }
  }
}

.list-box {
  border: 1px solid #ddd;
  border-radius: 6px;
  .list-box-header {
    height: 50px;
    display: flex;
    border-bottom: solid 1px #eeeeee;
    > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0 20px;
      width: 70%;
    }
    > div:last-child {
      width: 30%;
      border-left: 1px solid #ddd;
      :deep(.ivu-input) {
        height: 49px;
        line-height: 50px;
        border: none;
      }
      :deep(.ivu-icon) {
        height: 50px;
        line-height: 50px;
        font-size: 22px;
      }
    }
  }
  .list-box-content {
    padding: 20px;
    .list-item {
      display: flex;
      .checkbox {
        width: 16px;
        margin-right: 10px;
        padding-top: 5px;
      }
      .list-item-info {
        width: calc(100% - 26px);
        h2 {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 33px;
          color: #111111;
          margin-bottom: 10px;
        }
        > p {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 33px;
          letter-spacing: 0px;
          color: #888888;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
        .count {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          color: #555555;
          line-height: 33px;
        }
      }
      :deep(.ivu-checkbox-checked) {
        .ivu-checkbox-inner {
        }
      }
    }
  }
}
.list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  > div {
    display: flex;
  }
  > div:first-child {
    p {
      font-size: 14px;
      // span {
      //   color: #131313;
      //   font-weight: bold;
      //   padding: 0 3px;
      // }
    }
  }
}
</style>